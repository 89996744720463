<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
// import { fetchSettings } from "./api";
export default {
    props:{
        base:{}
    },
     page: {
        title: "Base de vies de l'entreprise",
        meta: [{ name: "description", content: appConfig.description }],
    },
    // import : { fetchSettings },
    components:{Layout,PageHeader},
    data(){
        return {
            title: "BaseLifes",
            items: [
                {
                text: "Paramètres",
                href: "javascript:void(0)",
                },
                {
                text: "BaseLife",
                active: true,
                },
            ],
        }
    },
    created(){
        // this.getLifeBase()
    },
    methods:{
        
    }
}
</script>
<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row">
            <div class="col-lg-3">
                <div class="card">
                    <h4 class="card-title mb-4">Informations Générales</h4>
                    <div class="table-responsive">
                        <table class="table table-nowarp mb-0">
                            <tr>
                                <th>Nom de la Base</th>
                                <td>{{}}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{{}}</td>
                            </tr>
                            <tr>
                                <th>Télephone</th>
                                <td>{{}}</td>
                            </tr>
                            <tr>
                                <th>Wilaya</th>
                                <td>{{}}</td>
                            </tr>
                            <tr>
                                <th>Addresse</th>
                                <td>{{}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
    
</template>